import {ref, computed, reactive} from 'vue'
import {$t} from "@/language";
import { defineStore } from 'pinia'
import {
  checkHasLanguage,
  defaultLang,
  loadLocaleMessages,
  setI18nLanguage,
  setLocalLanguage
} from "@/language";
import {getTenantId} from "@/utils/utils.commonTenantParams";
import {getUserPagePermissionList} from "@/api/modules/user";
import {ElMessage} from "element-plus";

/**
 * 将 公共底部栏信息 保存在浏览器本地缓存
 * @param params
 */
export function setLocalPublicFooterInfo(params) {
  let str = ""
  if(isObject(params)){
    try {
      str = JSON.stringify(params);
    }catch (e){
      console.error(e);
    }
  }
  localStorage.setItem("public_footer_info",str);
}
/**
 * 从浏览器本地缓存获取 公共底部栏信息
 * @param key
 */
export function getLocalPublicFooterInfo(key) {
  let info = null;
  try {
    info = localStorage.getItem('public_footer_info');
    if(info) info = JSON.parse(info);
  }catch (e) {
    console.error(e);
  }
  if(!isObject(info)) info = {};
  if(!(typeof key === 'string' && key)) return info;
  return info[key];
}
const preferentialMarginFlag = ref(false)
export function setPreferentialMarginFlag(val) {
  preferentialMarginFlag.value = val
}
export const useSystemStore = defineStore('system', () => {
  // 是否显示优惠价
  // let preferentialMarginFlag = ref(false)
  // 网站底部信息
  let webFooterInfo = ref(getLocalPublicFooterInfo()||{
    "id": 0,
    // ERP网站名称
    "erpWebsiteName": "",
    // OMS网站名称
    "omsWebsiteName": "",
    // ERP首页背景图
    "erpIndexBgImgUrl": "",
    // ERP首页登陆区域背景图
    "erpLoginAreaBgImgUrl": "",
    // ERP的系统名称
    "erpSystemName": "",
    // ERP系统登录页logo
    "erpLoginLogoUrl": "",
    // ERP和OMS左上角的logo
    "websiteLogoUrl": "",
    // OMS密码强度设置 -1 不限制 1 字母+数字 2 大小写字母 + 数字 3 大小写字母 + 数字 + 特殊字符,可用值:-1,1,2,3
    "omsPwdSetType": "",
    // ERP密码强度设置 -1 不限制 1 字母+数字 2 大小写字母 + 数字 3 大小写字母 + 数字 + 特殊字符s,可用值:-1,1,2,3
    "erpPwdSetType": "",
    // 主题色
    "themeColor": "",
    // 网站语言
    "websiteLanguage": "",
    // 微信公众号二维码URL
    "weChatQrCode": "",
    // 微信小程序二维码URL
    "weChatAppletQrCode": "",
    // 网站对应的主体
    "websitePrincipal": "",
    // 网站备案号
    "filingNumber": "",
    // 备案号连接
    "filingNumberUrl": "",
    // 网站上显示的联系人(公司名称)
    "contacts": "XXXXXXXXX货代公司",
    // 网站上显示的联系电话
    "telephone": "152XXXX2323",
    // 网站上显示的联系地址
    "address": "广东省深圳市XXXXXXXX号",
    // 客服二维码URL
    "serviceQrCode": "",
    // OMS系统名称
    "omsSystemName": "",
  });
  // 当前语言
  const language = ref(defaultLang);
  // 设置/切换语言
  async function setLanguage(value){
    console.log(`useSystemStore setLanguage(${value})`)
    if(!checkHasLanguage(value)){
      console.log(`未预加载${value}语言包，等待加载中...`);
      await loadLocaleMessages(value);
    }
    setLocalLanguage(value);
    setI18nLanguage(value);
    language.value = value;
  }

  // 设置 网站底部信息
  function setWebFooterInfo(value){
    if(!isObject(value)) value = {};
    setLocalPublicFooterInfo(value);
    webFooterInfo.value = value;
  }

  // 是否需要初始化公共配置信息
  let needInitCommonInfo = true;
  /**
   * 自动获取 网站底部信息
   * @param compelGet {boolean} 是否强制重新请求
   * @return {Promise<T|{}|MessageHandler|*>}
   */
  async function autoGetWebFooterInfo(compelGet = false){
    setWebHeaderTitle(unref(webFooterInfo).erpWebsiteName);
    if(needInitCommonInfo || compelGet){
      needInitCommonInfo = false;
      let loginResult = await getPublicFooterInfo();
      if(loginResult && loginResult.returnCode === "200"){
        let data = loginResult.data || {};
        setWebHeaderTitle(data.erpWebsiteName);
        setWebFooterInfo(data);
        return data;
      }
      if(loginResult && loginResult.returnCode === "700"){
        // 因租户配置不可用提示已在左侧弹出，所以不用提示
      }else{
        ElMessage.error(loginResult.returnMsg || loginResult.message || $t("login.systemException"));
      }
    }
    return unref(webFooterInfo);
  }
  function setWebHeaderTitle(title="") {
    if(title) document.title = title;
  }

  // 系统的一些基础配置
  const initConfig = ref();
  function setSystemConfig(data) {
    initConfig.value = data;
    localStorage.setItem('initConfig',JSON.stringify(initConfig.value));
  }

  function getSystemConfig() {
    try {
      return initConfig.data || JSON.parse(localStorage.getItem("initConfig"));
    } catch {
      return {}
    }
  }

  const tenantCode = ref('QH')
  function seTenantCode(val) {
    tenantCode.value = val.toUpperCase()
  }

  return {
    preferentialMarginFlag,
		language,
		setLanguage,
		webFooterInfo,
		setWebFooterInfo,
		autoGetWebFooterInfo,
		initConfig,
		getSystemConfig,
		setSystemConfig,
		tenantCode,
		seTenantCode,
    setPreferentialMarginFlag
	}
})

let beforePromise;
let footerInfoLoad = false;
/**
 * 获取 公共底部栏信息
 * @return {Promise<AxiosResponse<any>>}
 */
function getPublicFooterInfo(){
  if(footerInfoLoad) return beforePromise;
  const tenantId = getTenantId();
  if(!tenantId){
    return {"returnCode": "700", "returnMsg": "配置未完善，系统不可用"};
  }
  let url = `/tms/officalWebsiteConfig/getOfficalConfig/${tenantId}`
  let data = {};
  footerInfoLoad = true;
  return beforePromise = axiosMain.get(url)
    .then(res=>{
      footerInfoLoad = false;
      return res;
    })
    .catch(res=>{
      footerInfoLoad = false;
      return res;
    })
}