import { ref, computed, reactive } from "vue";
import { defineStore } from "pinia";
import { isArray } from "@/utils/utils";
import router from "@/router";
import {setLocalSourceType} from "@/utils/utils.commonTenantParams";

/**
 * 将token保存在浏览器本地缓存
 * @param token
 */
export function setLocalToken(token) {
  localStorage.setItem("token", token);
}

/**
 * 从浏览器本地缓存获取token
 * @return {string}
 */
export function getLocalToken() {
  return localStorage.getItem("token");
}

/**
 * 将userInfo保存在浏览器本地缓存
 * @param params
 */
export function setLocalUserInfo(params) {
  let str = "";
  if (isObject(params)) {
    try {
      str = JSON.stringify(params);
    } catch (e) {
      console.error(e);
    }
  }
  localStorage.setItem("user_info", str);
}
/**
 * 从浏览器本地缓存获取token
 * @param key
 */
export function getLocalUserInfo(key) {
  let user = null;
  try {
    user = localStorage.getItem("user_info");
    if (user) user = JSON.parse(user);
  } catch (e) {
    // console.warn("getLocalUserInfo 解析用户信息异常!");
    console.error(e);
  }
  if (!isObject(user)) user = {};
  if (!(typeof key === "string" && key)) return user;
  return user[key];
}
/**
 * 获取缓存的用户按钮权限列表
 * @return {Array<string>}
 */
function getLocalBtnPermission() {
  let value = localStorage.getItem("use_btn_permission_list");
  if (value) {
    try {
      let list = JSON.parse(value);
      if (!isArray(list)) list = [];
      return list;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
  return [];
}

/**
 * 缓存用户按钮权限列表
 * @param list  {Array<string>?} 页面权限列表 [btnKey1,btnKey2,btnKey3....]
 */
function setLocalBtnPermission(list) {
  if (!isArray(list)) list = [];
  localStorage.setItem("use_btn_permission_list", JSON.stringify(list));
}

/**
 * 获取缓存的用户子页面权限列表
 * @return {Array<string>}
 */
 function getLocalChildPagesPermission() {
  let value = localStorage.getItem("use_child_pages_permission_list");
  if (value) {
    try {
      let list = JSON.parse(value);
      if (!isArray(list)) list = [];
      return list;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
  return [];
}

/**
 * 缓存用户子页面权限列表
 * @param list  {Array<string>?} 页面权限列表 [btnKey1,btnKey2,btnKey3....]
 */
function setLocalChildPagesPermission(list) {
  if (!isArray(list)) list = [];
  localStorage.setItem("use_child_pages_permission_list", JSON.stringify(list));
}

/**
 * 获取缓存的用户子页面权限列表
 * @return {Array<string>}
 */
 function getLocalDataPermission() {
  let value = localStorage.getItem("use_data_permission_list");
  if (value) {
    try {
      let list = JSON.parse(value);
      if (!isArray(list)) list = [];
      return list;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
  return [];
}
/**
 * 缓存用户数据权限列表
 * @param list  {Array<string>?} 数据权限列表 [btnKey1,btnKey2,btnKey3....]
 */
 function setLocalDataPermission(list) {
  if (!isArray(list)) list = [];
  localStorage.setItem("use_data_permission_list", JSON.stringify(list));
}

/**
 * 获取缓存的用户页面权限列表
 * @return {Array<string>}
 */
export function getLocalPagePermission() {
  let value = localStorage.getItem("use_page_permission_list");
  if (value) {
    try {
      let list = JSON.parse(value);
      if (!isArray(list)) list = [];
      return list;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
  return [];
}

/**
 * 缓存用户页面权限列表
 * @param list  {Array<string>?} 页面权限列表 [path1,path2,path3....]
 */
export function setLocalPagePermission(list) {
  if (!isArray(list)) list = [];
  localStorage.setItem("use_page_permission_list", JSON.stringify(list));
}

// 所有权限code集合
let menuAuthorityCodes = disposeMenuAuthorityList(getLocalPagePermission());
/**
 * 判单是否有页面访问权限
 * @param toCode  {string|Array<string>}  页面对应的权限代码
 */
/**
 * 判单是否有页面访问权限
 * @param toCode  {string|Array<string>}  页面对应的权限代码
 * @param list  {Array<string>?} 所有权限code集合
 * @returns {boolean|*}
 */
export function checkPagePermission(toCode, list = menuAuthorityCodes) {
  // return true;
  // console.log("toCode",toCode);
  // console.log("list",list);

  // 各页面对应的权限代码
  // Track management	1	轨迹管理
  // Customer service management	1	客服管理
  // Order management	1	订单管理
  // Product system management	1	产品体系管理
  // Operation center	1	操作中心
  // Account management	1	账务管理
  // System management	1	系统管理
  // Basic data	1	基础数据
  // Initialize	1	初始设置
  // Home introduction setup	1	官网公司介绍配置
  // Official website operation guide configuration	1	官网操作指南配置
  // Home setup management	1	官网首页配置管理
  // Operation configuration	1	操作配置
  // Customer management	1	客户管理
  // Service provider management	1	服务商管理
  // Administrative management	1	行政管理
  // Financial management	1	财务管理
  // Market operation management	1	市场运营管理
  // Company management	1	公司信息管理
  // Role management	1	角色管理
  // Departmental management	1	部门信息管理
  // Employee management	1	员工信息管理
  // Reconciliation exchange rate management	1	对账汇率管理
  // Cost name management	1	费用名称管理
  // Bank account management	1	银行账号管理
  // Customer information management	1	客户信息管理
  // Service provider channel quotation maintenance	1	服务商渠道报价维护
  // Service provider information maintenance	1	服务商信息维护
  // Total billing weight management	1	总计费重量管理
  // Vehicle type management	1	车型管理
  // Address management	1	六级地址库管理
  // FBX Warehouse Management	1	FBX仓库管理
  // Postcode library management	1	邮编库管理
  // Dictionary management	1	数据字典管理
  // Face list label template configuration	1	面单标签模板配置
  // Product management	1	产品管理
  // Price inquiry	1	价格查询
  // API Settin	1	API设置
  // Billing ladder setting	1	计费阶梯设置
  //
  // Customer quotation management	1	客户报价管理
  // Product management	1	产品管理
  // Domestic transshipment quotation setting	1	国内转运报价设置
  // Zoning management	1	分区管理
  // Trial calculation of cost and freight	1	成本运费试算
  // Trial calculation of customer freight	1	客户运费试算
  // Pick-up task management	1	提货任务管理
  // Bill of lading management	1	提单管理
  // Order management	1	订单管理
  // Direct connect order management	1	专线订单管理
  // Management of waiting for delivery	1	待提货管理
  // Bill of lading management	1	提货单管理
  // Order track management	1	订单轨迹管理
  // Track mapping maintenance management	1	轨迹映射维护管理
  // Face sheet label printing	1	面单标签打印
  // Receiving management	1	收货管理
  // Shipping management	1	出货管理
  // Cargo scanning	1	货物扫描
  // Accounts receivable report	1	应收报表
  // Accounts Payable Management	1	应付账单管理
  // Customer account management	1	客户账户管理
  // Recharge review	1	充值审核
  // Details of accounts receivable	1	应收费用明细
  // Fee receivable management	1	应收费用管理
  // Accounts receivable management	1	应收账单管理
  // Payable expense management	1	应付费用管理
  // Accounts Payable Report	1	应付报表
  // Account flow management	1	账号流水管理
  // Operation log	1	操作日志
  // User login access log	1	用户登录访问日志
  // Work order management	1	工单管理
  // Trajectory query	1	轨迹查询

  if (isArray(toCode)) {
    return toCode.some((code) => list.includes(code));
  } else if (typeof toCode === "string") {
    return list.includes(toCode);
  }
}

/**
 * 请求用户菜单权限列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getMenuAuthorityList() {
  return api.user
    .getUserPagePermissionList()
    .then((res) => {
      console.log("获取权限菜单", res);
      let result = res.data || {};
      let list = result.tmsAuthorityList || [];
      const menus = list.filter(v => v.authorityType === 1)
      const btns = list.filter(v => v.authorityType === 4)
      const childPages = list.filter(v => v.authorityType === 6)
      const datas = list.filter(v => v.authorityType === 5)
      const userStore = useUserStore()
      menuAuthorityCodes = disposeMenuAuthorityList(menus);
      setLocalPagePermission(menus);
      userStore.setBtnPermission(btns.map(v => v.code))
      userStore.setChildPagesPermission(childPages.map(v => v.code))
      userStore.setDataPermission(datas.map(v => v.code))
      return res;
    })
    .catch((res) => {
      console.error(res);
      console.log("获取权限列表失败" + (res.message || res.returnMsg));
      const userStore = useUserStore()
      let list = [];
      setLocalPagePermission(list);
      userStore.setBtnPermission([])
      userStore.setChildPagesPermission([])
      userStore.setDataPermission([])
    });
}

/**
 * 处理获取的权限菜单列表数据
 * @param list
 * @returns {*[]}
 */
export function disposeMenuAuthorityList(list = []) {
  // code集合
  let codes = [];
  list.forEach((item) => {
    codes.push(item.code);
  });
  return codes;
}

export const useUserStore = defineStore("user", () => {
  const token = ref(getLocalToken() || "");
  const userInfo = ref(getLocalUserInfo() || {});
  let userName = computed(() => {
    if (isObject(unref(userInfo))) {
      return unref(userInfo).nameCn || "";
    } else {
      return "";
    }
  });
  let userId = computed(() => {
    if (isObject(unref(userInfo))) {
      return unref(userInfo).id || "";
    } else {
      return "";
    }
  });
  let customerId = computed(() => {
    if (isObject(unref(userInfo))) {
      return unref(userInfo).customerId || "";
    } else {
      return "";
    }
  });
  // 设置token
  function setToken(value) {
    token.value = value;
    setLocalToken(value);
  }
  // 设置userInfo
  function setUserInfo(value) {
    if (!isObject(value)) value = {};
    userInfo.value = value;
    setLocalUserInfo(value);
  }
  // 清除用户登录相关信息
  function clearUserLoginInfo(toLogin = true) {
    // localStorage.removeItem('user_info')
    setLocalSourceType();
    setToken("");
    setUserInfo();
    setBtnPermission();
    setChildPagesPermission()
    setDataPermission()
    setLocalPagePermission();
    if (toLogin)
      router.push({
        name: "login",
      });
  }

  // 用户按钮权限列表
  const btnPermission = ref(getLocalBtnPermission());
  /**
   * 设置用户按钮权限列表
   * @param list {Array<string>?}
   */
  function setBtnPermission(list) {
    if (!isArray(list)) list = [];
    btnPermission.value = list;
    setLocalBtnPermission(list);
  }

   // 用户子页面权限列表
   const childPagesPermission = ref(getLocalChildPagesPermission());
   /**
    * 设置用户子页面权限列表
    * @param list {Array<string>?}
    */
   function setChildPagesPermission(list) {
     if (!isArray(list)) list = [];
     childPagesPermission.value = list;
     setLocalChildPagesPermission(list);
   }
   // 用户数据权限列表
   const dataPermission = ref(getLocalDataPermission());
   /**
    * 设置用户数据权限列表
    * @param list {Array<string>?}
    */
   function setDataPermission(list) {
     if (!isArray(list)) list = [];
     dataPermission.value = list;
     setLocalDataPermission(list);
   }
  /**
   * 检查是否有指定的按钮权限
   * @param keys  {string|Array<string>|(function(Array<string>):boolean)}  指定的按钮key，如果类型为function,则回调的第一个参数为已有按钮权限列表
   * @param alreadyHaveList {Array<string>} 已有按钮权限列表
   * @return {boolean}
   */
  function checkBtnPermission(keys, alreadyHaveList = btnPermission.value) {
    if (!isArray(alreadyHaveList) || !alreadyHaveList.length) return false;
    if (isArray(keys))
      return keys.every((key) => alreadyHaveList.includes(key));
    if (typeof keys === "function") return keys(alreadyHaveList);
    return alreadyHaveList.includes(keys);
  }
  /**
   * 检查是否有指定的子页面权限
   * @param keys  {string|Array<string>|(function(Array<string>):boolean)}  指定的子页面key，如果类型为function,则回调的第一个参数为已有子页面权限列表
   * @param alreadyHaveList {Array<string>} 已有子页面权限列表
   * @return {boolean}
   */
  function checkChildPagesPermission(keys, alreadyHaveList = childPagesPermission.value) {
    if (!isArray(alreadyHaveList) || !alreadyHaveList.length) return false;
    if (isArray(keys))
      return keys.every((key) => alreadyHaveList.includes(key));
    if (typeof keys === "function") return keys(alreadyHaveList);
    return alreadyHaveList.includes(keys);
  }

  /**
   * 检查是否有指定的数据权限
   * @param keys  {string|Array<string>|(function(Array<string>):boolean)}  指定的数据key，如果类型为function,则回调的第一个参数为已有数据权限列表
   * @param alreadyHaveList {Array<string>} 已有数据权限列表
   * @return {boolean}
   */
   function checkDataPermission(keys, alreadyHaveList = dataPermission.value) {
    if (!isArray(alreadyHaveList) || !alreadyHaveList.length) return false;
    if (isArray(keys))
      return keys.every((key) => alreadyHaveList.includes(key));
    if (typeof keys === "function") return keys(alreadyHaveList);
    return alreadyHaveList.includes(keys);
  }
  /**
   * 获取用户信息
   * @param userId
   * @returns {*}
   */
  function requestUserInfo(userId) {
    return api.user
      .getUserInfo(userId)
      .then((res) => {
        let result = res.data || {};
        setUserInfo(result);
        return res;
      })
      .catch((res) => {
        console.error(res);
      });
  }

  /**
   * 快捷登录
   * @param token
   * @param userId
   */
  function quickLogin(token, userId) {
    clearUserLoginInfo(false);
    setToken(token);
    setLocalSourceType(2);
    return requestUserInfo(userId);
  }

  return {
    token,
    userInfo,
    userName,
    userId,
    customerId,
    setToken,
    setUserInfo,
    clearUserLoginInfo,
    btnPermission,
    setBtnPermission,
    checkBtnPermission,
    childPagesPermission,
    setChildPagesPermission,
    checkChildPagesPermission,
    dataPermission,
    setDataPermission,
    checkDataPermission,
    quickLogin,
    requestUserInfo,
  };
});
