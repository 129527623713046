const Layout = () => import("@/layout/layout.vue");
const PendingVue = () =>
  import("@/views/order/deliveryTaskManage/pendingDelivery.vue");

const route = [
  // {
  //   path: '/order',
  //   name: 'order',
  //   meta:{
  //     title:"layout",
  //   },
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/order/orderList",
  //       name: "orderList",
  //       meta: { title: "订单列表",check:["login","permission"],},
  //       component: () => import("@/views/order/orderList.vue")
  //     },
  //     {
  //       path: "/order/orderDetails",
  //       name: "orderDetails",
  //       meta: { title: "订单详情",check:["login","permission"],},
  //       component: () => import("@/views/order/orderDetails.vue")
  //     },
  //   ]
  // },
  {
    path: "/data",
    name: "data",
    meta: {
      title: "基础资料",
    },
    component: Layout,
    children: [
      {
        path: "/data/administration",
        name: "administration",
        meta: { title: "行政管理" },
        children: [
          {
            path: "/data/administration/comInfoManagement",
            name: "comInfoManagement",
            meta: {
              title: "公司信息管理",
              check: ["login", "permission"],
              permissionCode: ["T_companyManage"],
              keepAlive: true
            },
            component: () =>
              import("@/views/data/administration/comInfoManagement.vue"),
          },
          {
            path: "/data/administration/departManagement",
            name: "departManagement",
            meta: {
              title: "部门信息管理",
              check: ["login", "permission"],
              permissionCode: ["T_departmentManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/administration/departManagement.vue"),
          },
          {
            path: "/data/administration/employeeManagement",
            name: "employeeManagement",
            meta: {
              title: "登录用户管理",
              check: ["login", "permission"],
              permissionCode: ["T_employeeManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/administration/employeeManagement.vue"),
          },
          // {
          //   path: "/data/administration/roleManagement",
          //   name: "roleManagement",
          //   meta: {
          //     title: "角色管理",
          //     check: ["login", "permission"],
          //     permissionCode: ["T_roleManage"],
          //     keepAlive: true,
          //   },
          //   component: () =>
          //     import("@/views/system/roleManagement.vue"),
          // },
          {
            path: "/accountManage/safetyCenter",
            name: "safetyCenter",
            meta: { title: "个人设置", check: ["login"], keepAlive: true },
            component: () => import("@/views/accountManage/safetyCenter.vue")
          }
        ],
      },
      {
        path: "/data/travellingTraderManagement",
        name: "travellingTraderManagement",
        check: ["login", "permission"],
        meta: { title: "客商信息管理", permissionCode: ['T_customerInformationManagement']},
        // permissionCode: ['T_customerInformationManagement'],
        // permissionCode: ['T_customerInformationManagement'],
        component: () =>import("@/views/data/travellingTraderManagement.vue")
      },
      {
        path: "/data/travellingTraderManagement/add",
        name: "travellingTraderManagementAdd",
        meta: { title: "新增客商信息" },
        component: () =>import("@/views/data/travellingTraderManagementAdd.vue")
      },


      {
        path: "/data/travellingTraderManagement/details/:id",
        name: "客商详情",
        meta: { title: "" },
        component: () =>import("@/views/data/travellingTraderManagementDetail.vue")
      },

      {
        path: "/data/travellingTraderManagement/edit",
        name: "travellingTraderManagementEdit",
        meta: { title: "客商信息编辑" },
        component: () =>import("@/views/data/travellingTraderManagementEdit.vue")
      },


      {
        path: "/data/travellingTraderManagement/audit/:id",
        name: "客商审核",
        meta: {
          title: "",
          // check: ["login", "permission"],
        },
        component: () =>import("@/views/data/travellingTraderManagementAudit.vue")
      },
      {
        path: "/data/financialManagement",
        name: "financialManagement",
        // meta: { title: "财务管理" },
        meta: { title: "财务数据管理" },
        children: [
          {
            path: "/data/financialManagement/reconciliationRate",
            name: "reconciliationRate",
            meta: {
              title: "业务汇率管理",
              check: ["login", "permission"],
              permissionCode: ["T_operationalRateManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/financialManagement/reconciliationRate.vue"),
          },
          {
            path: "/data/financialManagement/bankAccount",
            name: "bankAccount",
            meta: {
              title: "银行账户管理",
              check: ["login", "permission"],
              permissionCode: ["T_bankAccountManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/financialManagement/bankAccount.vue"),
          },
          {
            path: "/data/financialManagement/costNameManagement",
            name: "costNameManagement",
            meta: {
              title: "费用名称管理",
              check: ["login", "permission"],
              permissionCode: ["T_costNameManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/financialManagement/costNameManagement.vue"),
          },
        ],
      },
      {
        path: "/data/customerManagement",
        name: "customerManagement",
        meta: { title: "客户管理", permissionCode: ["T_cusInfoManage"] },
        children: [
          {
            path: "/data/customerManagement/customerInformation",
            name: "customerInformation",
            meta: {
              title: "客户信息管理",
              check: ["login", "permission"],
              // permissionCode: ["Customer information management"],
              permissionCode: ["T_cusInfoManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/customerManagement/customerInformation.vue"),
          },
          {
            path: "/data/customerManagement/customerInformation/add",
            name: "customerInformationAdd",
            meta: { title: "新增客户信息" },
            component: () =>import("@/views/data/customerManagement/customerInformationAdd.vue")
          },

          {
            path: "/data/customerManagement/customerInformation/details/:id",
            name: "客户详情",
            meta: {
              title: "",
              check: ["login", "permission"],
            },
            component: () =>
              import(
                "@/views/data/customerManagement/customerInformationDetail.vue"
              ),
          },

          {
            path: "/data/customerManagement/customerInformation/audit/:id",
            name: "客户审核",
            meta: {
              title: "",
              check: ["login", "permission"],
            },
            component: () =>
              import(
                "@/views/data/customerManagement/customerInformationAudit.vue"
              ),
          },




          {
            path: "/data/customerManagement/customerInformation/edit",
            name: "customerInformationEdit",
            meta: { title: "客户信息编辑" },
            component: () =>import("@/views/data/customerManagement/customerInformationEdit.vue")
          },
          {
            path: "/data/customerManagement/customerOperationRecord",
            name: "customerOperationRecord",
            meta: {
              title: "客户操作访问记录",
              check: ["login", "permission"],
              permissionCode: ["T_cusOperateLogManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/customerManagement/customerOperationRecord.vue"),
          },
          /*{
            path: '/data/customerManagement/merchantInfo',
            name: 'merchantInfo',
            meta: {
              title: '客商信息',
              check: ['login'],
              keepAlive: true
            },
            component: () => import('@/views/data/components/merchantInfo.vue')
          }*/
        ],
      },

      {
        path: "/data/serviceProvider",
        name: "serviceProvider",
        meta: { title: "服务商管理", permissionCode: ['T_SPManage'] },
        children: [
          {
            path: "/data/serviceProvider/serviceProviderInformation",
            name: "serviceProviderInformation",
            meta: {
              title: "供应商信息管理",
              check: ["login", "permission"],
              // permissionCode: ["Service provider information maintenance"],
              permissionCode: ['T_SPInfoMaintenance'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/data/serviceProvider/serviceProviderInformation.vue"
              ),
          },
          {
            path: "/data/serviceProvider/serviceProviderInformation/details/:id",
            name: "serviceProviderInformationDetails",
            meta: {
              title: "",
              check: ["login", "permission"],
              // permissionCode: ["Service provider information maintenance"],
              permissionCode: ['T_SPInfoMaintenance']
            },
            component: () =>
              import(
                "@/views/data/serviceProvider/serviceProviderDetails.vue"
              ),
          },
          {
            path: "/data/serviceProvider/serviceProviderInformation/edit",
            name: "serviceProviderInformationEdit",
            meta: {
              title: "服务商编辑",
              check: ["login", "permission"],
              // permissionCode: ["Service provider information maintenance"],
              permissionCode: ['T_SPInfoMaintenance'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/data/serviceProvider/serviceProviderEdit.vue"
              ),
          },
          {
            path: "/data/serviceProvider/serviceProviderInformation/add",
            name: "serviceProviderInformationAdd",
            meta: {
              title: "服务商新增",
              check: ["login", "permission"],
              // permissionCode: ["Service provider information maintenance"],
              permissionCode: ['T_SPInfoMaintenance'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/data/serviceProvider/serviceProviderAdd.vue"
              ),
          },
          {
            path: "/data/serviceProvider/quotationMaintenance",
            name: "quotationMaintenance",
            meta: {
              title: "服务商渠道报价维护",
              check: ["login", "permission"],
              // permissionCode: [
              //   "Service provider channel quotation maintenance",
              // ],
              permissionCode: ['T_SPQuoteMaintenance'],
              keepAlive: true
            },
            component: () =>
              import("@/views/data/serviceProvider/quotationMaintenance.vue"),
          },
          {
            path: "/data/serviceProvider/costPlusPrice/costPlusPriceHdp",
            name: "costPlusPriceHdp",
            meta: {
              title: "货代拼成本加价",
              check: ["login", "permission"],
              permissionCode: [
                "Huodaipin cost markup",
                // "Service provider channel quotation maintenance",
              ],
              keepAlive: true
            },
            component: () =>
              import("@/views/data/serviceProvider/costPlusPrice/costPlusPriceHdp.vue"),
          },
          {
            path: "/data/serviceProvider/channelProviderCheck",
            name: "channelProviderCheck",
            meta: {
              title: "渠道对接管理",
              check: ["login", "permission"],
              // permissionCode: [
              //   "Service provider channel quotation maintenance",
              // ],
              permissionCode: ['T_channelSPConnectApplicationManage'],
              keepAlive: true
            },
            component: () =>
              import("@/views/data/serviceProvider/channelProviderCheck.vue"),
          },
          {
            path: "/data/serviceProvider/quotationMaintenance/add",
            name: "quotationMaintenanceAdd",
            meta: {
              title: "渠道报价新增",
              check: ["login", "permission"],
              permissionCode: [
                // "Service provider channel quotation maintenance",
                "T_SPQuoteMaintenance",
              ],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/data/serviceProvider/quotaionAdd.vue"
              ),
          },
          {
            path: "/data/serviceProvider/quotationMaintenance/details",
            name: "quotationMaintenanceDetails",
            meta: {
              title: "渠道报价详情",
              check: ["login", "permission"],
              permissionCode: [
                // "Service provider channel quotation maintenance",
                "T_SPQuoteMaintenance"
              ],
            },
            component: () =>
              import(
                "@/views/data/serviceProvider/quotaionDetails.vue"
              ),
          },
          {
            path: "/data/serviceProvider/quotationMaintenance/edit",
            name: "quotationMaintenanceEdit",
            meta: {
              title: "渠道报价修改",
              check: ["login", "permission"],
              permissionCode: [
                // "Service provider channel quotation maintenance",
                "T_SPQuoteMaintenance"
              ],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/data/serviceProvider/quotaionEdit.vue"
              ),
          },
        ],
      },
      {
        path: "/data/marketOperations",
        name: "marketOperations",
        meta: { title: "市场运营管理" },
        children: [
          {
            path: "/data/marketOperations/vehicleModelManagement",
            name: "vehicleModelManagement",
            meta: {
              title: "车型管理",
              check: ["login", "permission"],
              permissionCode: ["Vehicle type management"],
              keepAlive: true,
            },
            component: () =>
              import(
                "@/views/data/marketOperations/vehicleModelManagement.vue"
              ),
          },
          {
            path: "/data/marketOperations/totalBillingWeight",
            name: "totalBillingWeight",
            meta: {
              title: "总计费重量管理",
              check: ["login", "permission"],
              permissionCode: ["Total billing weight management"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/marketOperations/totalBillingWeight.vue"),
          },
          {
            path: "/data/marketOperations/fbxWarehouseManagement",
            name: "fbxWarehouseManagement",
            meta: {
              title: "FBX仓库管理",
              check: ["login", "permission"],
              permissionCode: ["T_fbxWarehouseManage"],
              keepAlive: true,
            },
            component: () =>
              import(
                "@/views/data/marketOperations/fbxWarehouseManagement.vue"
              ),
          },
          {
            path: "/data/marketOperations/nationalAddressManagement",
            name: "nationalAddressManagement",
            meta: {
              title: "六级地址库管理",
              check: ["login", "permission"],
              permissionCode: ["Address management"],
              keepAlive: true,
            },
            component: () =>
              import(
                "@/views/data/marketOperations/nationalAddressManagement.vue"
              ),
          },
          {
            path: "/data/marketOperations/postalCodeLibraryManagement",
            name: "postalCodeLibraryManagement",
            meta: {
              title: "邮编库管理",
              check: ["login", "permission"],
              permissionCode: ["Postcode library management"],
              keepAlive: true,
            },
            component: () =>
              import(
                "@/views/data/marketOperations/postalCodeLibraryManagement.vue"
              ),
          },
          {
            path: "/data/marketOperations/dataDictionary",
            name: "dataDictionary",
            meta: {
              title: "数据字典管理(旧)",
              check: ["login", "permission"],
              permissionCode: ["Dictionary management"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/marketOperations/dataDictionary.vue"),
          },
          {
            path: "/data/marketOperations/dataLib",
            name: "dataLib",
            meta: {
              title: "数据字典管理",
              check: ["login", "permission"],
              permissionCode: ["Dictionary management"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/marketOperations/dataLib.vue"),
          },
          {
            path: "/data/marketOperations/protocolManagement",
            name: "protocolManagement",
            meta: {
              title: "协议管理",
              check: ["login", "permission"],
              permissionCode: ["protocol management"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/marketOperations/protocolManagement.vue")
          },
          {
            path: "/data/marketOperations/productsTypeManagement",
            name: "productsTypeManagement",
            meta: {
              title: "货物类型管理",
              check: ["login", "permission"],
              permissionCode: ["cargoType"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/data/marketOperations/productsTypeManagement.vue")
          },
        ],
      },
      // {
      //   path: "/data/orderStatusManagement",
      //   name: "orderStatusManagement",
      //   meta: { title: "订单状态管理",check:["login","permission"],permissionCode:[""],,},
      //   component: () => import("@/views/data/orderStatusManagement.vue")
      // }
    ],
  },
  {
    path: "/products",
    name: "products",
    meta: {
      // title: "产品体系管理",
      title: "报价管理",
      permissionCode: ['Product system management']
    },
    component: Layout,
    children: [
      // {
      //   path: "/products/productManagement",
      //   name: "productManagement",
      //   meta: { title: "产品管理" },
      //   children: [
          {
            path: "/products/productManagement/Partitioning",
            name: "Partitioning",
            meta: {
              title: "分区管理",
              check: ["login", "permission"],
              permissionCode: ["T_zoneManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/products/productManagement/Partitioning.vue"),
          },
          {
            path: "/products/productManagement/billingLadderSettings",
            name: "billingLadderSettings",
            meta: {
              title: "计费阶梯设置",
              check: ["login", "permission"],
              permissionCode: ["T_billingLadderSetting"],
              keepAlive: true,
            },
            component: () =>
              import(
                "@/views/products/productManagement/billingLadderSettings.vue"
              ),
          },
          {
            path: "/products/productManage",
            name: "productManage",
            meta: {
              title: "产品管理",
              check: ["login", "permission"],
              // permissionCode: ["Product management1"],
              permissionCode: ['T_productManage'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/products/productManage/index.vue"
              ),
          },
          {
            path: "/products/productManage/add",
            name: "productManageAdd",
            meta: {
              title: "新增产品",
              check: ["login", "permission"],
              // permissionCode: ["Product management"],
              permissionCode: ['T_productManage'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/products/productManage/productAdd.vue"
              ),
          },
          {
            path: "/products/productManage/edit",
            name: "productManageEdit",
            meta: {
              title: "编辑产品",
              check: ["login", "permission"],
              // permissionCode: ["Product management"],
              permissionCode: ['T_productManage'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/products/productManage/productEdit.vue"
              ),
          },
          {
            path: "/products/productManage/details/:id",
            name: "productManageDetails",
            meta: {
              title: "查看产品",
              check: ["login", "permission"],
              // permissionCode: ["Product management"],
              permissionCode: ['T_productManage'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/products/productManage/productDetails.vue"
              ),
          },
          {
            path: "/products/productManagement/productManagement",
            name: "productManagement",
            meta: {
              title: "产品管理（旧）",
              check: ["login", "permission"],
              // permissionCode: ["Product management1"],
              permissionCode: ['T_productManage']
            },
            component: () =>
              import(
                "@/views/products/productManagement/productManagement.vue"
              ),
          },
          {
            path: "/products/productManagement/add",
            name: "productManagementAdd",
            meta: {
              title: "产品管理新增",
              check: ["login", "permission"],
              // permissionCode: ["Product management"],
              permissionCode: ['T_productManage'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/products/productManagement/productAdd.vue"
              ),
          },
          {
            path: "/products/productManagement/edit",
            name: "productManagementEdit",
            meta: {
              title: "产品管理修改",
              check: ["login", "permission"],
              // permissionCode: ["Product management"],
              permissionCode: ['T_productManage'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/products/productManagement/productEdit.vue"
              ),
          },
          {
            path: "/products/productManagement/details",
            name: "productManagementDetails",
            meta: {
              title: "产品管理详情",
              check: ["login", "permission"],
              // permissionCode: ["Product management"],
              permissionCode: ['T_productManage'],
            },
            component: () =>
              import(
                "@/views/products/productManagement/productDetails.vue"
              ),
          },
          {
            path: "/products/productManagement/customerQuotationSettings",
            name: "customerQuotationSettings",
            meta: {
              title: "报价设置",
              check: ["login", "permission"],
              // permissionCode: ["Customer quotation management"],
              permissionCode: ['T_cusQuoteSetting'],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/products/productManagement/customerQuotationSettings.vue"
              ),
          },
      {
        path: "/products/productManagement/customerQuotationSettings/add",
        name: "addCustomerQuotation",
        meta: {
          title: "新增客户报价",
          check: ["login"],
          keepAlive: true
        },
        component: () =>
          import(
            "@/views/products/productManagement/customerQuotationAdd.vue"
            ),
      },
      {
        path: "/products/productManagement/customerQuotationSettings/edit",
        name: "editCustomerQuotation",
        meta: {
          title: "修改客户报价",
          keepAlive: true
        },
        component: () =>
          import(
            "@/views/products/productManagement/customerQuotationEdit.vue"
            ),
      },
      {
        path: "/products/productManagement/customerQuotationSettings/details/:id",
        name: "CustomerQuotationDetail",
        meta: {
          title: "查看客户报价",
          check: ["login"],
          keepAlive: true
        },
        component: () =>
          import(
            "@/views/products/productManagement/customerQuotationDetails.vue"
            ),
      },
      {
        path: "/products/productManagement/customerQuotationSettings/copy",
        name: "CustomerQuotationCopy",
        meta: {
          title: "复制客户报价",
          check: ["login"],
          keepAlive: true
        },
        component: () =>
          import(
            "@/views/products/productManagement/customerQuotationCopy.vue"
            ),
      },
        ],
    //   },
    // ],
  },
  {
    path: "/order",
    name: "order",
    meta: {
      title: "订单管理",
      permissionCode: ['T_orderManage']
    },
    component: Layout,
    children: [
      {
        path: "/order/specialLineOrderManagement",
        name: "specialLineOrderManagement",
        meta: {
          title: "电商订单管理",
          check: ["login", "permission"],
          // permissionCode: ["Order management1"],
          permissionCode: ['T_directOrderManage'],
          keepAlive: true
        },
        component: () => import("@/views/order/specialLineOrderManagement.vue"),
      },

      {
        path: "/order/specialLineOrderManagement/add",
        name: "specialLineOrderManagementAdd",
        meta: {
          title: "新增订单",
          check: ["login", "permission"],
          // permissionCode: ["Order management1"],
          // permissionCode: ['T_directOrderManage'],
          keepAlive: true
        },
        component: () => import("@/views/order/specialLineOrderManagementAdd.vue"),
      },
      {
        path: "/order/specialLineOrderManagement/specialLineOrderDetail/:nos",
        name: "specialLineOrderDetail",
        meta: {
          title: "专线订单详情",
          check: ["login", "permission"],
          // permissionCode: ["Order management1"],
          permissionCode: ['T_directOrderManage'],
          isOrderDetail: true
        },
        component: () => import("@/views/order/specialLineOrderDetail.vue"),
      },
      {
        path: "/order/DeliveryTaskManagement",
        name: "orderTaskManagement",
        meta: {
          title: "提货任务管理",
        },
        children: [
          {
            path: "/order/DeliveryTaskManagement/pendingDeliveryManagement",
            name: "pendingDeliveryManagement",
            meta: {
              title: "待提货管理",
              check: ["login", "permission"],
              permissionCode: ["T_waitingPickUpManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/order/deliveryTaskManage/pendingDelivery.vue"),
          },
          {
            path: "/order/DeliveryTaskManagement/waybillManage",
            name: "waybillManage",
            meta: {
              title: "提货单管理",
              check: ["login", "permission"],
              permissionCode: ["T_ladingBillManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/order/deliveryTaskManage/waybillManage.vue"),
            // children: [

            // ]
          },
          {
            path: "/order/DeliveryTaskManagement/detail",
            name: "detail",
            meta: {
              title: "提货单详情",
              check: ["login", "permission"],
              permissionCode: ["T_ladingBillManage"],
            },
            component: () =>
              import("@/views/order/deliveryTaskManage/detail/detail-page.vue"),
          },
        ],
      },
      {
        path: "/order/stowageListManagement/stowage",
        name: "stowage",
        meta: {
          title: "配载管理",
          check: ["login", "permission"],
          // permissionCode: ["Load management"],
          permissionCode: ['T_loadManage'],
          keepAlive: true
        },
        component: () => import("@/views/order/stowageManagement/stowage.vue"),
      },
      {
        path: "/order/stowageManage",
        name: "stowageManage",
        meta: {
          title: "配载管理",
          check: ["login", "permission"],
          // permissionCode: ["Load management"],
          permissionCode: ['T_loadManage'],
          keepAlive: true
        },
        component: () => import("@/views/order/stowageManage/index.vue"),
      },
      {
        path: "/order/orderPushManagement",
        name: "orderPushManagement",
        meta: {
          title: "订单推送管理",
          check: ["login", "permission"],
          permissionCode: ["T_orderPushManage"],
          keepAlive: true,
        },
        component: () => import("@/views/order/orderPushManagement.vue"),
      },
    ],
  },
  {
    path: "/trajectory",
    name: "trajectory",
    meta: {
      title: "轨迹管理",
      permissionCode: ['T_trackManage']
    },
    component: Layout,
    children: [
      {
        path: "/trajectory/trajectoryMainManege",
        name: "trajectoryMainManege",
        meta: {
          title: "轨迹维护管理",
          check: ["login", "permission"],
          permissionCode: ["Track mapping maintenance management"],
          keepAlive: true,
        },
        component: () => import("@/views/trajectory/trajectoryMainManege.vue"),
      },
      {
        path: "/trajectory/orderTrackManagement",
        name: "orderTrackManagement",
        meta: {
          title: "订单轨迹管理",
          check: ["login", "permission"],
          // permissionCode: ["Order track management"],
          permissionCode: ["T_orderTrackManage"],
          keepAlive: true
        },
        component: () => import("@/views/trajectory/orderTrackManagement.vue"),
      },
    ],
  },
  {
    path: "/operation-center",
    name: "operation-center",
    meta: {
      title: "操作中心",
      permissionCode: ['T_operationManage']
    },
    component: Layout,
    children: [
      {
        path: "/operation-center/receipt",
        name: "receipt",
        meta: {
          title: "收货管理",
          permissionCode: ['T_receivingManage'],
        },
        children: [
          {
            path: "/operation-center/receipt/list",
            name: "receipt",
            meta: {
              title: "收货订单监控",
              check: ["login", "permission"],
              // permissionCode: ["ReceivingOrderMonitoring"],
              permissionCode: ["T_receivingOrderMonitor"],
              keepAlive: true
            },
            component: () => import("@/views/operation-center/receipt/receipt.vue"),
          },
          {
            path: "/operation-center/receipt/scan",
            name: "receiptScan",
            meta: {
              title: "收货扫描",
              check: ["login", "permission"],
              permissionCode: ["ReceivingScan"],
              keepAlive: true,
            },
            component: () => import("@/views/operation-center/receipt/Scan.vue"),
          },
          {
            path: "/operation-center/receipt/receiptHistory",
            name: "receiptHistory",
            meta: {
              title: "收货扫描记录",
              check: ["login", "permission"],
              permissionCode: ["ReceivingHistory"],
              keepAlive: true,
            },
            component: () => import("@/views/operation-center/receipt/receiptHistory.vue"),
          },
        ]
      },
      {
        path: "/operation-center/shipping",
        name: "shipping",
        meta: {
          title: "出货管理",
        },
        children: [
          {
            path: "/operation-center/shipping/list",
            name: "shipping",
            meta: {
              title: "出货订单监控",
              check: ["login", "permission"],
              permissionCode: ["T_shippingOrderMonitor"],
              keepAlive: true
            },
            component: () => import("@/views/operation-center/shipping/shipping.vue"),
          },
          {
            path: "/operation-center/shipping/scan",
            name: "shippingScan",
            meta: {
              title: "出货扫描",
              check: ["login", "permission"],
              permissionCode: ["ShippingScan"],
              keepAlive: true,
            },
            component: () => import("@/views/operation-center/shipping/Scan.vue"),
          },
          {
            path: "/operation-center/shipping/shippingHistory",
            name: "shippingHistory",
            meta: {
              title: "出货扫描记录",
              check: ["login", "permission"],
              permissionCode: ["ShippingHistory"],
              keepAlive: true,
            },
            component: () => import("@/views/operation-center/shipping/shippingHistory.vue"),
          },
        ]
      },
      {
        path: "/operation-center/sheetLabel",
        name: "receiptPrint",
        meta: {
          title: "面单标签打印",
          check: ["login", "permission"],
          permissionCode: ["Face sheet label printing"],
          keepAlive: true,
        },
        component: () => import("@/views/operation-center/sheetLabel.vue"),
      },
    ],
  },

  {
    path: "/financial-management",
    name: "Account management",
    meta: {
      // title: "财务管理",
      title: "结算管理",
      permissionCode: ['T_accountManage']
    },
    component: Layout,
    children: [
      {
        path: "/RechargeManagement",
        name: "RechargeManagement",
        meta: { title: "充值管理",permissionCode: ['T_rechargeManage'] },
        children:[
          {
            path: "/financial-management/statement/customerAccountManage",
            name: "customerAccountManage",
            meta: {
              title: "客户账户管理",
              check: ["login", "permission"],
              // permissionCode: ["Customer account management"],
              permissionCode: ["T_cusAccountManage"],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/financial-management/statement/customerAccountManage.vue"
              ),
          },
          {
            path: "/financial-management/statement/accountFlowManage",
            name: "accountFlowManage",
            meta: {
              // title: "账户流水管理",
              title: '客户流水管理',
              check: ["login", "permission"],
              // permissionCode: ["Account flow management"],
              permissionCode: ["T_cusFlowManage"],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/financial-management/statement/accountFlowManage.vue"
              ),
          },
          {
            path: "/financial-management/rechargeReview",
            name: "rechargeReview",
            meta: {
              title: "充值审核",
              check: ["login", "permission"],
              // permissionCode: ["Recharge review"],
              permissionCode: ["T_rechargeAudit"],
              keepAlive: true,
            },
            component: () =>
              import(
                "@/views/financial-management/rechargeReview/rechargeReview.vue"
              ),
          },
        ]
      },
      {
        path: "/CostManagement",
        name: "CostManagement",
        meta: { title: "费用管理", permissionCode: ['T_costManage'] },
        children:[
          {
            path: "/financial-management/receipt-count-detail",
            name: "receiptCountDetail",
            meta: {
              title: "应收费用明细",
              check: ["login", "permission"],
              permissionCode: ["Details of accounts receivable"],
              keepAlive: true
            },
            component: () =>
              import("@/views/financial-management/receipt-count-detail/index.vue"),
          },

          {
            path: "/financial-management/receipt-count-manage/list",
            name: "receiptCountManageList",
            meta: {
              title: "订单应收费用管理",
              check: ["login", "permission"],
              // permissionCode: ["Fee receivable management"],
              permissionCode: ["T_orderReceivableFeeManage"],
              keepAlive: true
            },
            component: () =>
              import("@/views/financial-management/receipt-count-manage/page.vue"),
          },
          {
            path: "/financial-management/receipt-count-manage/detail",
            name: "receiptCountManageDetail",
            meta: {
              title: "应收费用管理明细页",
              check: ["login", "permission"],
              // permissionCode: ["Fee receivable management"],
              permissionCode: ["T_orderReceivableFeeManage"],
            },
            component: () =>
              import(
                "@/views/financial-management/receipt-count-manage/detail.vue"
              ),
          },
          {
            path: "/financial-management/paied-coast-details",
            name: "paiedCoastDetails",
            meta: {
              title: "应付费用明细",
              check: ["login", "permission"],
              permissionCode: ["DetailsOfPayableExpenses"],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/financial-management/paied-coast-details/index.vue"
              ),
          },
          {
            path: "/financial-management/paied-expenses/list",
            name: "paiedExpensesList",
            meta: {
              title: "订单应付费用管理",
              check: ["login", "permission"],
              // permissionCode: ["Payable expense management"],
              permissionCode: ["T_orderPayableFeeManage"],
              keepAlive: true
            },
            component: () =>
              import(
                "@/views/financial-management/paied-expenses/paied-expenses.vue"
              ),
          },
          {
            path: "/financial-management/paied-expenses/detail",
            name: "paiedExpensesDetail",
            meta: {
              title: "应付费用管理明细",
              check: ["login", "permission"],
              // permissionCode: ["Payable expense management"],
              permissionCode: ["T_orderPayableFeeManage"],
            },
            component: () =>
              import("@/views/financial-management/paied-expenses/detail.vue"),
          },
          {
            path: "/financial-management/receipt-paid-coast-manage/list",
            name: "receiptPaidCoastManageList",
            meta: {
              title: "费用明细管理",
              check: ["login", "permission"],
              // permissionCode: ["Payable expense management"],
              permissionCode: ["T_feeDetailManage"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/financial-management/receipt-paid-coast-manage/index.vue"),
          },
        ]
      },
      {
        path: '/financial-management/bill-management',
        name: 'BillManagement',
        meta: {
          title: "账单管理",
          check: ["login", "permission"],
          permissionCode: ["T_newbillManage"],
          keepAlive: true,
        },
        component: () =>
            import("@/views/financial-management/bill-management/index.vue")
      },
      // {
      //   path: "/BillManagement",
      //   name: "BillManagement",
      //   meta: { title: "账单管理", permissionCode: ['T_billManage'] },
      //   children:[
      //     {
      //       path: "/financial-management/receipt-bill/list",
      //       name: "receiptBillList",
      //       meta: {
      //         // title: "应收账单管理",
      //         title: "客户账单管理",
      //         check: ["login", "permission"],
      //         // permissionCode: ["Accounts receivable management"],
      //         permissionCode: ["T_cusBillManage"],
      //         keepAlive: true
      //       },
      //       component: () =>
      //         import("@/views/financial-management/receipt-bill/receipt-bill.vue"),
      //     },
      //     {
      //       path: "/financial-management/receipt-bill/detail",
      //       name: "receiptBillDetail",
      //       meta: {
      //         title: "客户账单明细",
      //         check: ["login", "permission"],
      //         // permissionCode: ["Accounts receivable management"],
      //         permissionCode: ["T_cusBillManage"],
      //       },
      //       component: () =>
      //         import("@/views/financial-management/receipt-bill/detail.vue"),
      //     },
      //     {
      //       path: "/financial-management/paied-manage/list",
      //       name: "paiedNamageList",
      //       meta: {
      //         // title: "应付账单管理",
      //         title: "服务商账单管理",
      //         check: ["login", "permission"],
      //         // permissionCode: ["Accounts Payable Management"],
      //         permissionCode: ["T_SPBillManage"],
      //         keepAlive: true
      //       },
      //       component: () =>
      //         import("@/views/financial-management/paied-manage/paied-manage.vue"),
      //     },
      //     {
      //       path: "/financial-management/paied-manage/detail",
      //       name: "paiedNamageDetail",
      //       meta: {
      //         title: "服务商账单明细",
      //         check: ["login", "permission"],
      //         // permissionCode: ["Accounts Payable Management"],
      //         permissionCode: ["T_SPBillManage"],
      //       },
      //       component: () =>
      //         import("@/views/financial-management/paied-manage/detail.vue"),
      //     },
      //   ]
      // },
      {
        path: "/StatisticalAnalysis",
        name: "StatisticalAnalysis",
        meta: { title: "统计分析" },
        children:[
          {
            path: "/financial-management/statement/receivableStatement",
            name: "receivableStatement",
            meta: {
              title: "应收报表",
              check: ["login", "permission"],
              permissionCode: ["Accounts receivable report"],
              keepAlive: true,
            },
            component: () =>
              import(
                "@/views/financial-management/statement/receivableStatement.vue"
              ),
          },
          {
            path: "/financial-management/statement/statementPayable",
            name: "statementPayable",
            meta: {
              title: "应付报表",
              check: ["login", "permission"],
              permissionCode: ["Accounts Payable Report"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/financial-management/statement/statementPayable.vue"),
          },
          {
            path: "/financial-management/financial-report-forms",
            name: "financialReportForms",
            meta: {
              title: "财务报表",
              check: ["login", "permission"],
              permissionCode: ["FinancialStatements"],
              keepAlive: true,
            },
            component: () =>
              import("@/views/financial-management/financial-report-forms/index.vue"),
          },
        ]
      },
      {
        path: "/financial-management/financialOrderManagement",
        name: "financialOrderManagement",
        meta: {
          title: "财务订单管理",
          check: ["login", "permission"],
          permissionCode: ["T_accountOrderManage"],
          keepAlive: true,
        },
        component: () =>
            import("@/views/financial-management/financialOrdersManagement/index.vue")
      },
      {
        path: "/financial-management/write-off-management",
        name: "writeOffManagement",
        meta: {
          title: "核销管理",
          check: ["login", "permission"],
          permissionCode: ["T_writeOffManage"],
          keepAlive: true,
        },
        component: () =>
            import("@/views/financial-management/write-off-management/index.vue")
      }
    ],
  },
  {
    path: "/salesmanManagement",
    name: "salesmanManagement",
    meta: {
      title: "业务员管理",
    },
    component: Layout,
    children: [
      {
        path: '/mySalesmanManagement',
        name: 'Salesman Management',
        meta: {
          title: '我的业务管理'
        },
        children: [
          {
            path: "/salesmanManagement/salesmanCustomer",
            name: "salesmanCustomer",
            meta: {
              title: "我的客户资料",
              check: ["login", "permission"],
              permissionCode: ["T_myCustomerProfile"],
              keepAlive: true
            },
            component: () =>
              import("@/views/salesmanManagement/salesmanCustomer.vue"),
          },
          {
            path: "/salesmanManagement/myOrder/list",
            name: "myOrder",
            meta: {
              title: "我的业务订单",
              check: ["login", "permission"],
              permissionCode: ["T_myBussinessOrder"],
              keepAlive: true
            },
            component: () =>
              import("@/views/salesmanManagement/myOrder/mySpecialLineOrderManagement.vue"),
          },
          {
            path: "/salesmanManagement/myOrder/subManagement",
            name: "subManagement",
            meta: {
              title: "订阅管理",
              check: ["login", 'permission'],
              permissionCode: ['T_subscribeManage'],
              keepAlive: true
            },
            component: () =>
              import("@/views/salesmanManagement/myOrder/subManagement.vue"),
          },
          {
            path: "/salesmanManagement/mySpecialLineOrderDetail/:nos",
            name: "mySpecialLineOrderDetail",
            meta: {
              title: "查看订单明细",
              check: ["login", "permission"],
              permissionCode: ["T_myBussinessOrder"],
            },
            component: () =>
              import("@/views/salesmanManagement/myOrder/mySpecialLineOrderDetail.vue"),
          },
          // {
          //   path: "/salesmanManagement/myOrderTrackManagement",
          //   name: "myOrderTrackManagement",
          //   meta: {
          //     title: "订单轨迹管理",
          //     check: ["login", "permission"],
          //     permissionCode: ["My Order"],
          //     keepAlive: true
          //   },
          //   component: () => import("@/views/trajectory/orderTrackManagement.vue"),
          // },
        ]
      }
    ],
  },
  {
    path: "/serviceManagement",
    name: "serviceManagement",
    meta: {
      title: "客服管理",
    },
    component: Layout,
    children: [
      {
        path: "/serviceManagement/userLoginRecord",
        name: "userLoginRecord",
        meta: {
          title: "用户登录访问日志",
          check: ["login", "permission"],
          permissionCode: [""],
          keepAlive: true,
        },
        component: () =>
          import("@/views/serviceManagement/userLoginRecord.vue"),
      },
    ],
  },

  // {
  //   path: '/tenant',
  //   name: 'tenant',
  //   meta:{
  //     title:"layout",
  //   },
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/tenant/tenantManagement",
  //       name: "tenantManagement",
  //       meta: { title: "租户管理",check:["login","permission"],permissionCode:[""],,},
  //       component: () => import("@/views/tenant/tenantManagement.vue")
  //     },
  //     {
  //       path: "/tenant/tenantPackageManagement",
  //       name: "tenantPackageManagement",
  //       meta: { title: "租户套餐管理",check:["login","permission"],permissionCode:[""],,},
  //       component: () => import("@/views/tenant/tenantPackageManagement.vue")
  //     },
  //   ]
  // },
  // {
  //   path: '/account',
  //   name: 'account',
  //   meta:{
  //     title:"layout",
  //   },
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/accountManage/departmentInfo",
  //       name: "departmentInfo",
  //       meta: { title: "部门管理",check:["login","permission"],permissionCode:[""],,},
  //       component: () => import("@/views/accountManage/departmentInfo.vue")
  //     },
  //     {
  //       path: "/account/roleManagement",
  //       name: "roleManagement",
  //       meta: { title: "角色管理",check:["login","permission"],permissionCode:[""],,},
  //       component: () => import("@/views/account/roleManagement.vue")
  //     },
  //     {
  //       path: "/accountManage/safetyCenter",
  //       name: "safetyCenter",
  //       meta: { title: "安全中心",check:["login","permission"],permissionCode:[""],,},
  //       component: () => import("@/views/accountManage/safetyCenter.vue")
  //     },
  //     {
  //       path: "/accountManage/staffManage",
  //       name: "staffManage",
  //       meta: { title: "员工管理",check:["login","permission"],permissionCode:[""],,},
  //       component: () => import("@/views/accountManage/staffManage.vue")
  //     },
  //   ]
  // },
];
export default route;
