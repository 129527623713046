<template>
  <div class="cus-page-bar">
    <el-pagination
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :small="small"
      :disabled="disabled"
      :background="background"
      :layout="layout || 'total, sizes, prev, pager, next'"
      :total="total"
      :pager-count="4"
      @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script setup>
  const props = defineProps({
    modeName: String,
    currentPage: Number,
    pageSize: Number,
    total: Number,
    pageCount: Number,
    small: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    background: String,
    layout: String,
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100]
    }
  })

  const emit = defineEmits([
    'update:current-page',
    'update:page-size',
    'size-change',
    'current-change'
  ])
  function handleSizeChange(val) {
    emit('update:page-size', val)
    emit('size-change', val)
  }
  function handleCurrentChange(val) {
    emit('update:current-page', val)
    emit('current-change', val)
  }
</script>

<style lang="scss" scoped>
  .cus-page-bar {
    margin-top: 5px;
    .el-pagination {
      justify-content: start;
      :deep .el-pagination__sizes {
        margin-left: 10px!important;
        width: 70px;
        .el-input {
          height: 28px;
          width: 70px;
          border-radius: 3px;
          .el-icon.el-select__icon {
            margin-left: 2px;
          }
        }
      }
      :deep .btn-prev {
        margin-left: 4px!important;
      }
    }
  }
</style>