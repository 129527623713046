import axiosMain from "@/axios";
import {getTenantId} from "@/utils/utils.commonTenantParams";

/**
 * 获取 官网信息列表接口
 * @param blockCode {string}  对应内容块代码
 公告消息	AnnouncementMessage
 banner图	BannerPicture
 价格查询	PriceInquiry
 覆盖国家	CoverageCountry
 优势线路	DominantLine
 限时折扣	DiscountInLimitedTime
 新闻中心	PressCenter
 合作客户	CooperativeClient
 服务流程	ServiceProcess
 底部 Bottom
 我的优势	MyAdvantage
 组织点亮	TissueLighting
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getHomeInfoList(blockCode){
  let _tenantId = getTenantId();
  if(!blockCode) {
    return Promise.reject(Error("缺少 对应内容块代码"));
  }
  let url = `/oms/officalWebsiteInfo/list/${_tenantId}/${blockCode}`;
  return axiosMain.get(url);
}