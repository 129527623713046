<template>
  <el-config-provider :locale="elementLocale">
    <RouterView />
  </el-config-provider>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useSystemStore } from "@/stores/system";
import { getDefaultLange, getI18nCurrentLanguage, getLocalLanguage } from "@/language";
import zh from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
// import {elementLanguageImport, elementLanguageMessages} from "@/language/elment";
import { alterWebPrimaryColor, getLocalPrimaryColor } from "@/utils/utils.system";
import { getConfig } from "@/views/system/systemHome";
import { autoRefresh } from '@/utils/autoUpdate';
// 在App.vue中
import { provide, nextTick } from 'vue'
const isRouterActive = ref(true)
provide('reload', () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
})


const systemStore = useSystemStore();
const elementLocale = ref(zh);
async function setElementLanguage(lang) {
  console.log(`setElementLanguage(${lang})`)
  // 直接引用
  if (lang === "en") {
    elementLocale.value = en;
  } else {
    elementLocale.value = zh;
  }
  // import("./*.js")使用
  // if(lang === 'zh'){
  //   elementLocale.value = zh;
  // }else if(elementLanguageMessages[lang]){
  //   console.log('缓存');
  //   elementLocale.value = elementLanguageMessages[lang];
  // }else if(elementLanguageImport[lang]){
  //   console.log(`import('${lang}')`);
  //   let messages = await elementLanguageImport[lang]();
  //   console.log(messages)
  //   elementLocale.value = messages.default;
  // }
}
onMounted(() => {
  console.log("App.vue onMounted()");
  let defaultLang = getDefaultLange();
  let currentLang = getI18nCurrentLanguage();
  console.log(defaultLang, currentLang)
  if (defaultLang !== currentLang) {
    systemStore.setLanguage(defaultLang);
    setElementLanguage(defaultLang);
  }
  let primaryColor = getLocalPrimaryColor();
  if (primaryColor) alterWebPrimaryColor(primaryColor);
  nextTick(() => {
		if (import.meta.env.MODE !== 'development') autoRefresh();
	});
	getConfig()
})

watch(() => {
  return systemStore.language;
}, (val) => {
  console.log("App.vue watch systemStore.language", val);
  setElementLanguage(val)
})
</script>

<style scoped></style>
