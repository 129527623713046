import {btnPermise, pagePermise, dataPermise} from "@/directives/modules/btnPermission";

/**
 * 挂载自定义指令插件
 */
export const mountDirectives = {
  install(app, options) {
    // 配置此应用
    app.directive("permission", btnPermise);
    app.directive("pagepower", pagePermise);
    app.directive("datapower", dataPermise);
  }
}