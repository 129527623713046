import "@/assets/style/main.scss";
import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import "@/assets/style/element/index.scss";
import "@/assets/style/element/dark.scss";
import ElementPlus from "element-plus";
// import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

import axios from "./axios/index";
import VueAxios from "vue-axios";

import "nprogress/nprogress.css";

// mock会导致导出接口导出的文件打开是乱码  参考https://www.cnblogs.com/stcweb/articles/16066788.html
// import '@/mock/index'

import { i18n } from "@/language";
import { mountDirectives } from "@/directives";
// 全局引入封装好的分页组件
import customPageBar from "@/components/customPageBar/index.vue";
import localforage from 'localforage'
// lodash引入问题参考 https://www.rollupjs.com/guide/faqs
// import _ from "lodash"
// window._ = _;
import ClickOutside from 'element-plus/es/directives/click-outside/index'

const app = createApp(App);
app.config.warnHandler = () => null;
app.directive('click-outside', ClickOutside)
app.use(createPinia());
app.use(router);
app.use(ElementPlus, {
  locale: zhCn,
});
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.component("customPageBar", customPageBar);
app.use(VueAxios, axios);
app.use(i18n);
app.use(mountDirectives);
console.log(localforage)
app.config.globalProperties.$forage = localforage

app.mount("#app");

console.log("环境变量", import.meta.env);
