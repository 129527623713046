const login = {
  zh:{
    loginButton:"登录",
    resetButton:"重置",
    accountNumber:"账号",
    password:"密码",
    accountPasswordError:"账号密码错误",
    systemException:"系统异常",
    loginSuccess:"登录成功",
    logout:"退出登录",
    confirmLogout:"确认退出登录？",
  },
  en:{
    loginButton:"login",
    resetButton:"reset",
    accountNumber:"account",
    password:"password",
    accountPasswordError:"Account password error",
    systemException:"System exception",
    loginSuccess:"Login successful",
    logout:"logout",
    confirmLogout:"Confirm logout？",
  }
}
export default login