/**
 * 判断传入值是否是有效对象
 * @param value
 * @return boolean
 */
import {getSystemType} from "@/utils/utils.commonTenantParams";

export function isArray(value) {
  if (typeof Array.isArray === "function") {
    return Array.isArray(value);
  } else {
    return Object.prototype.toString.call(value) === "[object Array]";
  }
}
/**
 * 判断传入值是否是有效对象
 * @param value
 * @return boolean
 */
export function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}
/**
 * 判断为空
 * @param val
 * @returns {boolean}
 */
export const isEmpty = function(val) {
  // null or undefined
  if (val === null) return true;

  if (typeof val === 'boolean') return false;

  if (typeof val === 'number') return !val;

  if (val instanceof Error) return val.message === '';

  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !val.length;

    // Map or Set or File
    case '[object File]':
    case '[object Map]':
    case '[object Set]': {
      return !val.size;
    }
    // Plain Object
    case '[object Object]': {
      return !Object.keys(val).length;
    }
  }

  return false;
};
/**
 * 清空对象中值为空的key
 * @param data
 * @returns {*}
 */
export function objectRemoveEmpty (data) {
  let keys = Object.keys(data).filter(res=>empty(data[res]));
  keys.forEach(key=>{
    delete data[key]
  });
  function empty(res){
    if(res === 0) return false;
    return isEmpty(res)
  }
  return data
}

/**
 * 账号密码登录
 * @param data {{name:string,pwd:string,verificationCode:string}} name:登录账号;pwd:登录密码;
 * @return {Promise<AxiosResponse<any>>}
 */
export function login(data) {
  if(!isObject(data)) return Promise.reject(Error("缺少登录账号密码"));
  if(!data.name) return Promise.reject(Error("缺少登录账号"));
  if(!data.pwd) return Promise.reject(Error("缺少登录密码"));
  if(!data.verificationCode) return Promise.reject(Error("缺少验证码"));
  let url = "/tms/user/login";
  return axiosMain.post(url, data)
}
/**
 * 账号退出
 * @param data {{name:string,pwd:string}} name:登录账号;pwd:登录密码;
 * @return {Promise<AxiosResponse<any>>}
 */
export function logout(data) {
  // if(!isObject(data)) return Promise.reject(Error("缺少登录账号密码"));
  // if(!data.name) return Promise.reject(Error("缺少登录账号"));
  // if(!data.pwd) return Promise.reject(Error("缺少登录密码"));
  let url = "/tms/user/logout";
  return axiosMain.post(url, data)
}

/**
 * 获取用户信息
 */
export function getUserInfo(userId){
  let url = `/tms/user/userInfo/${userId}`;
  return axiosMain.get(url);
}
/**
 * 获取用户页面权限列表
 */
export function getUserPagePermissionList() {
  let systemType = getSystemType();
  let url = "/tms/authority/menu/" + systemType ;
  return axiosMain.post(url);
}
/**
 * 获取验证码
 */
export function verificationCode(data) {
  let url = "/tms/user/verificationCode";
  return axiosMain.post(url);
}
