
import {useUserStore} from "@/stores/user";

export const btnPermise = {
  mounted: function (el, binding) {
    el.oridisplayForPr = el.style.display
    setTimeout(() => {
      el.oridisplayForPr = el.style.display
      const display = el.style.display
      const userStore = useUserStore();
      el.style.display = 'none'
      const btns = userStore.btnPermission
      // console.log(el)

        if (btns === undefined) {
          el.parentNode.removeChild(el)
          return
        }
        if (userStore.checkBtnPermission(binding.value.k, btns) || !binding.value.k) {
          el.style.display = display
        } else {
          el.parentNode.removeChild(el)
        }
    }, 0)
  },
  updated: function (el, binding) {
    setTimeout(() => {
      // debugger
      const display = el.style.display !== 'none' ? el.style.display : el.oridisplayForPr
      const userStore = useUserStore();
      el.style.display = 'none'
      const btns = userStore.btnPermission
      // console.log(el)

        if (btns === undefined) {
          el.parentNode.removeChild(el)
          return
        }
        if (userStore.checkBtnPermission(binding.value.k, btns) || !binding.value.k) {
          el.style.display = display
        } else {
          // el.parentNode?.removeChild(el)
          // el.parentNode && el.parentNode.removeChild(el)
        }
    }, 0)
  }
}
export const pagePermise = {
  mounted: function (el, binding) {
    el.oridisplayForPr = el.style.display
    setTimeout(() => {
      const display = el.style.display
      el.oridisplayForPr = el.style.display
      const userStore = useUserStore();
      el.style.display = 'none'
      const pages = userStore.childPagesPermission

        if (pages === undefined) {
          el.parentNode.removeChild(el)
          return
        }
        if (userStore.checkChildPagesPermission(binding.value.k, pages) || !binding.value.k) {
          el.style.display = display
        } else {
          el.parentNode?.removeChild(el)
        }
    }, 0)
  },
  updated: function (el, binding) {
    setTimeout(() => {
      const display = el.style.display !== 'none' ? el.style.display : el.oridisplayForPr
      const userStore = useUserStore();
      el.style.display = 'none'
      const pages = userStore.childPagesPermission

        if (pages === undefined) {
          el.parentNode.removeChild(el)
          return
        }
        if (userStore.checkChildPagesPermission(binding.value.k, pages) || !binding.value.k) {
          el.style.display = display
        } else {
          // el.parentNode?.removeChild(el)
        }
    }, 0)
  }
}
export const dataPermise = {
  mounted: function (el, binding) {
    el.oridisplayForPr = el.style.display
    setTimeout(() => {
      // const display = el.style.display
      // el.oridisplayForPr = el.style.display
      // const userStore = useUserStore();
      // el.style.display = 'none'
      // const pages = userStore.childPagesPermission

      //   if (pages === undefined) {
      //     el.parentNode.removeChild(el)
      //     return
      //   }
      //   if (userStore.checkChildPagesPermission(binding.value.k, pages) || !binding.value.k) {
      //     el.style.display = display
      //   } else {
      //     el.parentNode?.removeChild(el)
      //   }
    }, 0)
  },
  updated: function (el, binding) {
    setTimeout(() => {
      const display = el.style.display !== 'none' ? el.style.display : el.oridisplayForPr
      const userStore = useUserStore();
      el.style.display = 'none'
      const pages = userStore.dataPermission

        if (pages === undefined) {
          el.parentNode.removeChild(el)
          return
        }
        if (userStore.checkDataPermission(binding.value.k, pages) || !binding.value.k) {
          el.style.display = display
        } else {
          // el.parentNode?.removeChild(el)
        }
    }, 0)
  }
}
