const Layout = () => import("@/layout/layout.vue");
const route = [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录页",
    },
    component: () => import("@/views/login/newlogin.vue"),
  },
  // {
  //   path: "/newlogin",
  //   name: "newlogin",
  //   meta: {
  //     title: "新登录页",
  //   },
  //   component: () => import("@/views/login/newlogin.vue"),
  // },
  {
    path: "/testLink",
    name: "testLink",
    meta: {
      title: {
        zh: "测试用页面",
        en: "test page",
      },
    },
    component: () => import("@/views/testLink/index.vue"),
  },
  {
    path: "/system",
    name: "system",
    meta: {
      title: "layout",
    },
    component: Layout,
    children: [
      {
        path: "/system/systemHome",
        name: "systemHome",
        meta: {
          title: {
            zh: "我的工作台",
            en: "Management background Home page",
          },
          hideClose: true,
          check: ["login"],
        },
        component: () => import("@/views/system/systemHome.vue"),
      },
      {
        path: "/system/systemManagement",
        name: "systemManagement",
        meta: { title: "系统管理页", check: ["login"], keepAlive: true, permissionCode:["T_systemManage"] },
        component: () => import("@/views/system/systemManagement.vue"),
      },
      {
        path: "/system/Initialize",
        name: "Initialize",
        meta: { title: "系统配置", check: ["login","permission"],permissionCode:["Initialize"], keepAlive: true, },
        component: () => import("@/views/system/Initialize.vue"),
      },
      {
        path: "/system/homeManagement",
        name: "homeManagement",
        meta: { title: "官网首页配置管理", check: ["login","permission"],permissionCode:["Home setup management"], keepAlive: true, },
        component: () => import("@/views/system/homeManagement.vue"),
      },
      {
        path: "/system/companyManagement",
        name: "companyManagement",
        meta: { title: "公司介绍配置管理", check: ["login","permission"],permissionCode:["Home introduction setup"], keepAlive: true, },
        component: () => import("@/views/system/companyManagement.vue"),
      },
      {
        path: "/system/instructionConfig",
        name: "instructionConfig",
        meta: { title: "官网操作指南配置", check: ["login","permission"],permissionCode:["Official website operation guide configuration"], keepAlive: true, },
        component: () => import("@/views/system/instructionConfig.vue"),
      },


      {
        path: "/system/companyNewsManagement",
        name: "companyNewsManagement",
        meta: { title: "公司新闻配置管理", check: ["login","permission"],permissionCode:["T_companyNewsConfigManage"], keepAlive: true, }, // 异常权限
        component: () => import("@/views/system/companyNewsManagement.vue"),
      },

      {
        path: "/system/roleManagement",
        name: "roleManagement",
        meta: { title: "角色管理", check: ["login","permission"],permissionCode:["Home setup management"], keepAlive: true, },
        component: () => import("@/views/system/roleManagement.vue"),
      },









      // {
      //   path: '/system/:pathMatch(.*)',
      //   name: 'system404',
      //   component: () => import("@/views/system/404.vue")
      // },
    ],
  },
  {
    path: "/notPermission",
    name: "notPermission",
    component: () => import("@/views/system/notPermission.vue"),
  },
  // {
  //   path: '/:pathMatch([a-zA-Z0-9_-]+)/:pathMatch(.*)',
  //   name: 'NotFoundInner',
  //   redirect:{
  //     name:"system404",
  //   },
  // },
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: () => import("@/views/system/404.vue"),
  },
  {
    path: '/accountManage/bindWechat',
    name: 'bindWechat',
    component: () => import("@/views/accountManage/bindWechat.vue")
  }
];
export default route;
