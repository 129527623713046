import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NProgress from 'nprogress'
import { $t } from "@/language";
import { checkPagePermission, getMenuAuthorityList, useUserStore } from "@/stores/user";
import system from "@/router/modules/system";
import pages from "@/router/modules/pages";
import examples from "@/router/modules/examples";
import {
  getRouteMeta,
  getRouterMetaTitle,
  isLoginPage,
  isNeedCheckRouteProperty,
  mergeCurrentPagePathParams, removeParamsBeforeTheJumpLogin, setParamsBeforeTheJumpLogin
} from "@/utils/utils.router";
import { autoGetTenantId, getTenantId, requestTenantId, setLocalSourceType } from "@/utils/utils.commonTenantParams";

// 配置NProgress
NProgress.configure({ showSpinner: false, })

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: "systemHome"
    }
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  ...pages,
  ...system,
  ...examples,
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     if (savedPosition) {
    //       resolve(savedPosition)
    //     } else {
    //       resolve({ top: 0, left: 0 })
    //     }
    //   }, 500)
    // })
  },
})
// 是否需要初始化页面菜单访问权限
let needInitMenuAuthority = true;
//挂载路由导航守卫
router.beforeEach(async (to, from, next) => {
  if(from.meta.title==="电商订单管理" && to.meta.title==='专线订单详情'){
    to.meta.title = to.params.nos
  }
  if(from.meta.title==="我的业务订单" && to.meta.title==='查看订单明细'){
    to.meta.title = to.params.nos
  }
  if (from.meta.title === "财务订单管理" && to.meta.title === '专线订单详情') {
    to.meta.title = to.params.nos
  }
  if (to.name === 'serviceProviderInformationEdit') {
    to.meta.title = `服务商编辑`
  }
  if (to.name === 'serviceProviderInformationDetails') {
    to.meta.title = `服务商详情-${to.query.name}`
  }
  if (to.name === 'productManageDetails') {
    to.meta.title = to.query.title
  }
  console.log("to", to)
  console.log("from", from)
  // console.log("to", to.meta.title)
  // if (to.meta.title === '专线订单详情') {
  //   // sessionStorage.setItem("tabs",to.params.orderId);
  //   let arr = JSON.parse(sessionStorage.getItem("tabs") || "[]")
  //   arr.push(to)
  //   sessionStorage.setItem("tabs", JSON.stringify(arr))
  // }
  NProgress.start()
  let userStore = useUserStore();
  let query = to.query;
  const titleText = `从【${from.path}】页面跳转【${to.path}】时：`;
  // 租户id
  await autoGetTenantId();
  // 快捷登录
  if (query.type === "quickLogin") {
    if (query.token && query.userId) {
      userStore.quickLogin(query.token, query.userId);
      removeParamsBeforeTheJumpLogin();
      next({
        path: "/system/systemHome"
      })
    }
  }
  // 公共底部配置
  let systemStore = useSystemStore();
  await systemStore.autoGetWebFooterInfo();
  // 用户权限
  // let userStore = useUserStore();
  if (needInitMenuAuthority) {
    needInitMenuAuthority = false;
    if (userStore.token) {
      await getMenuAuthorityList();
    }
  }

  if (!isLoginPage(to.path)) {
    // 需要登录
    let needLogin = isNeedCheckRouteProperty(to, "login");
    if (needLogin) {
      let userStore = useUserStore();
      if (!userStore.token) {
        console.log(titleText + "需要先登录");
        const record = mergeCurrentPagePathParams(to);
        setParamsBeforeTheJumpLogin(record);
        return next({
          path: "/login"
        });
      }
    }
  }
  // 需要页面访问权限
  let needPermission = isNeedCheckRouteProperty(to, "permission");
  if (needPermission) {
    let permissionCode = getRouteMeta(to).permissionCode;
    console.log(titleText + "需要页面访问权限", permissionCode);
    let hasPermission = checkPagePermission(permissionCode);
    if (!hasPermission) {
      console.log(titleText + "访问页面权限不足");
      console.error(Error(`访问页面权限不足:${titleText};${to.fullPath}`,));
      return next({
        name: "notPermission",
        query: {
          path: to.path,
          query: JSON.stringify(to.query),
          params: JSON.stringify(to.params),
        },
      });
    }
  }
  console.log(titleText)
  next();
});
router.afterEach((to, form) => {
  NProgress.done()
  // let title = getRouterMetaTitle(to);
  // if(title) document.title = title;
  // else document.title = $t("system.systemName");
})

export default router
