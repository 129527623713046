/**
 * 改变网站主题色
 * @param color
 */
export function alterWebPrimaryColor(color){
  setLocalPrimaryColor(color);
  let cssText = "";
  if(color){
    cssText = `--el-color-primary:${color};`
  }
  const body = document.querySelector("body")
  // document.querySelector('body').style.cssText = cssText;
  body.style.setProperty("--el-color-primary", color);
  if (color) {
    let [colorR, colorG, colorB] = hexToRgb(color)
    body.style.setProperty("--el-color-primary-r", colorR)
    body.style.setProperty("--el-color-primary-g", colorG)
    body.style.setProperty("--el-color-primary-b", colorB)
  }
}

/**
 * hex转rgb
 * @param {string} hex
 * @returns {number[]}
 */
export function hexToRgb(hex) {
  if (!hex) return []
  let color = hex.replace("#", "");

  const red = parseInt(color.substring(0,2), 16)
  const green = parseInt(color.substring(2, 4), 16)
  const blue = parseInt(color.substring(4, 6), 16)
  console.log(red, green, blue);
  return [red, green, blue]
}

/**
 * 在本地缓存网站主题色
 * @param val
 */
export function setLocalPrimaryColor(val) {
  localStorage.setItem("primary_color",val);
}

/**
 * 获取缓存的网站主题色
 * @return {string|string}
 */
export function getLocalPrimaryColor() {
  return localStorage.getItem("primary_color") || "#2B62ED";
}