import axiosMain from "@/axios";
import { getTenantId } from "@/utils/utils.commonTenantParams";
import { useSystemStore } from "@/stores";
// 获取个人首页
export function getPersonInfo(data) {
    let url = `/tms/user/homepage`
    return axiosMain.post(url, data)
}

export function getConfig() {
    const tenantId = getTenantId();
    const systemStore = useSystemStore();
    axiosMain.get(`/tms/sysConfig/tenant/${tenantId}`).then(res => {
        if (res.returnCode == 200) {
            systemStore.setSystemConfig(res.data);
        } else {
            systemStore.setSystemConfig({});
        }
    })
        .catch(() => systemStore.setSystemConfig({}))
}
